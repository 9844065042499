var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    ref: "functionshome",
    staticClass: "goBackHome",
    on: {
      touchstart: _vm.onmousedownhome,
      touchmove: _vm.onmousemovehome,
      touchend: _vm.onmouseuphome
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/goback.png")
    },
    on: {
      click: _vm.handleGoBack
    }
  }), _c("div", {
    staticClass: "goBackHome-name"
  }, [_vm._v("主页")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };