// 本地环境配置
module.exports = {
  title: '新冠病毒感染的新型药物与疫苗接种策略研究',
  baseApi: 'https://dev-national-api.yidao.pro/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  // baseApi: 'https://localhost:5001/', // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  imagePrefix: 'https://dev-national-api.yidao.pro/api/File/GetFileContent/',
  cdnImagePrefix: 'https://cdn-national.yidao.pro/',
  codeUrl: 'https://dev-national.yidao.pro',
  connection_url: 'https://dev-national-api.yidao.pro/hub/ls-interact',
  weixinImage: '3b150d4c-8a54-4860-99f0-f5ef84990eaf',
  userKey: 'admin.user',
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://www.sunniejs.cn/static'
};