// axios
import request from '@/utils/request';

// 检查是否预约
export function checkAppointment(meetingId) {
  return request({
    url: '/api/v1/Meeting/CheckAppointment',
    method: 'get',
    params: {
      meetingId: meetingId
    }
  });
}

// 检查是否会议报名
export function checkSign(meetingId) {
  return request({
    url: '/api/v1/Meeting/CheckSign',
    method: 'get',
    params: {
      meetingId: meetingId
    }
  });
}

// 会议报名
export function getSignIn(meetingId) {
  return request({
    url: '/api/v1/Meeting/SignIn',
    method: 'post',
    params: {
      meetingId: meetingId
    }
  });
}

// 会议预约
export function makeAppointment(meetingId) {
  return request({
    url: '/api/v1/Meeting/MakeAppointment',
    method: 'post',
    params: {
      meetingId: meetingId
    }
  });
}

// 获取会议
export function getMeeting(meetingId) {
  return request({
    url: '/api/v1/Meeting/Get',
    method: 'get',
    params: {
      meetingId: meetingId
    }
  });
}

// 根据fileId 拿到对应文件
export function getFilesFromMeeting(fileId) {
  return request({
    url: '/api/v1/Meeting/GetFilesFromCourse',
    method: 'get',
    params: {
      fileId: fileId
    }
  });
}

/**
 * 判断流可用性
 * @param {直播流地址} url
 */
export function checkStream(url) {
  return request({
    url: url,
    method: 'get'
  });
}

/**
 *  增加观看时长
 */
export function addMeetingViewTimes(meetingId, times) {
  return request({
    url: '/api/v1/Meeting/AddMeetingViewTimes',
    method: 'get',
    params: {
      meetingId: meetingId,
      times: times
    }
  });
}

/**
 *  获取推流地址
 */
export function getMeetingLiveAddress(meetingId) {
  return request({
    url: '/api/v1/Meeting/GetMeetingAddress',
    method: 'get',
    params: {
      meetingId: meetingId
    }
  });
}

/**
 *  根据系列会议id获取对应的会议
 */
export function getMeetingArray(meetingIds) {
  return request({
    url: '/api/v1/Meeting/GetArray',
    method: 'post',
    data: meetingIds
  });
}

/**
 *  获取在线人数
 */
export function GetOnlineUserCount(meetingId) {
  return request({
    url: '/api/v1/Meeting/GetOnlineUserCount/GetOnlineUserCount',
    method: 'get',
    params: {
      meetingId: meetingId
    }
  });
}

// 链接SignalR
export function linkConnection() {
  return request({
    url: '/api/v1/Account/LoginSignalR',
    method: 'get'
  });
}