// 正式
module.exports = {
  title: '国家传染病医学中心',
  baseApi: 'https://national-api.yidao.pro/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  imagePrefix: 'https://national-api.yidao.pro/api/File/GetFileContent/',
  cdnImagePrefix: 'https://cdn-national.yidao.pro',
  codeUrl: "https://national.yidao.pro",
  connection_url: "https://national-api.yidao.pro/hub/ls-interact",
  weixinImage: "3dc83981-d70e-4ef4-bea3-300c7cb1ef03",
  userKey: "admin.user",
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://www.sunniejs.cn/static'
};