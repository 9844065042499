// axios
import request from '@/utils/request';
import { removeAuthorization } from '@/utils/request';
import { userKey } from '@/config';
import $router from '@/router';

// 根据code 获取微信OpenId
export function GetWxOpenId(code, state) {
  return request({
    url: '/api/v1/Account/GetWxOpenId/GetWxOpenId',
    method: 'get',
    params: {
      code: code,
      state: state
    }
  });
}

//微信注册用户
export function wxRegister(data) {
  return request({
    url: '/api/v1/Account/ExternalLogin',
    method: 'post',
    data: data
  });
}

//手机号登录
export function phoneLogin(data) {
  return request({
    url: '/api/v1/Account/SignWithPhoneNumber',
    method: 'post',
    data: data
  });
}

//发送短信
export function phoneCode(phoneNumber) {
  return request({
    url: "/api/v1/Account/SendLoginToken",
    method: 'get',
    params: {
      phoneNumber: phoneNumber
    }
  });
}

//根据opneId拿到token
export function getTokenByOpenId(openId) {
  return request({
    url: '/api/v1/Account/GetToken',
    method: 'get',
    params: {
      openId: openId
    }
  });
}

// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/api/v1/Account/GetUserInfo',
    method: 'get'
  });
}

// 获取当前登录用户的医生认证状态
export function checkDoctorCertificateStatus() {
  return request({
    url: '/api/v1/Account/CheckDoctorCertificateStatus',
    method: 'get'
  });
}

// 获取当前登录用户的医生认证信息
export function getDoctorCertificateInfo() {
  return request({
    url: '/api/v1/Account/GetDoctorCertificateInfo',
    method: 'get'
  });
}

// 是否关注过公众号
export function checkSubscribe() {
  return request({
    url: '/api/Wx/CheckSubscribe',
    method: 'get'
  });
}

// 提交客服信息
export function saveCustomer(data) {
  return request({
    url: '/api/v1/Customer/Save',
    method: 'post',
    data: data
  });
}

// 修改用户信息
export function modifyUserInfo(data) {
  return request({
    url: '/api/v1/Account/UpdateUserInfo',
    method: 'put',
    data: data
  });
}

// 完善用户信息
export function usettingUserInfo(data) {
  return request({
    url: '/api/v1/Account/UpdateUserIdentityInfo',
    method: 'put',
    data: data
  });
}

// 完善用户信息
export function seachArticle(searchText, page, size) {
  return request({
    url: '/api/v1/Test/FullContentSearch',
    method: 'get',
    params: {
      s: searchText,
      page: page,
      size: size
    }
  });
}

// 获取微信签到
export function GetJsApiConfig(url) {
  return request({
    url: '/api/Wx/GetJsApiConfig',
    method: 'get',
    params: {
      url: url
    }
  });
}

// 查询用户列表
export function queryUserList(isFront, enabled, isDoctorCertificate, pageSize, page) {
  return request({
    url: '/api/mgt/User/Query',
    method: 'get',
    params: {
      isFront: isFront,
      enabled: enabled,
      isDoctorCertificate: isDoctorCertificate,
      pageSize: pageSize,
      page: page
    }
  });
}

/**
 * 退出登录
 */

var logoOut = function logoOut() {
  return request({
    url: '/api/v1/Account/SignOut',
    method: 'post'
  });
};
export function logout() {
  logoOut();
  localStorage.removeItem(userKey);
  removeAuthorization();
  // debugger
  // router.push({ path: '/login' })
  //
  //   return request({
  //     url: '/api/v1/Account/SignOut',
  //     method: 'post'
  //   })
}