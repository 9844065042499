import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import { userKey } from '@/config';
export default {
  namespaced: true,
  state: {
    user: undefined
  },
  getters: {
    user: function user(state) {
      if (!state.user) {
        try {
          var user = localStorage.getItem(userKey);
          state.user = JSON.parse(user);
        } catch (e) {
          console.error(e);
        }
      }
      return state.user;
    }
  },
  mutations: {
    setUser: function setUser(state, user) {
      state.user = user;
      localStorage.setItem(userKey, JSON.stringify(user));
    }
  }
};