import Customer from '@/components/customer';
import GoBack from '@/components/goback';
export default {
  name: 'App',
  components: {
    Customer: Customer,
    GoBack: GoBack
  },
  data: function data() {
    return {
      network: true
    };
  },
  mounted: function mounted() {
    this.checkNetWork();
  },
  created: function created() {
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
      window.entryUrl = window.location.href;
    }
  },
  methods: {
    checkNetWork: function checkNetWork() {
      var _this = this;
      window.addEventListener('offline', function () {
        console.log('已断网');
        _this.network = false;
      });
      window.addEventListener('online', function () {
        console.log('网络已连接');
        _this.network = true;
      });
    }
  }
};