// 医生认证类型
export var CERT_TYPE = {
  UNKNOW: 0,
  //未知
  DOCTOR: 1,
  // 医生
  SCHOOLDOCOR: 2,
  // 医学生
  OTHER: 3 // 其他医疗行业从业人员
};

// 医生认证状态
export var CERT_STATUS = {
  NOACTION: 0,
  //待审核
  SUCCESS: 1,
  // 已通过
  FAILED: 2 // 已驳回
};

export var CONTENT_TYPE = {
  TJLBT: '推荐轮播图',
  JQHY: '近期会议',
  JBKC: '精编课程',
  TJZTPX: '推荐专题培训',
  ZXLBT: '资讯轮播图',
  HYLBT: '会议轮播图',
  KCLBT: '课程轮播图',
  KCZTPX: '课程专题培训',
  KCTJ: '课程推荐',
  MSJT: '名师讲堂',
  HYTJ: '会议推荐',
  LSHYJX: '历史会议精选',
  GDLSHY: '更多历史会议',
  ZXZY: '最新资讯',
  XLHY: '系列会议',
  ZLT: '主论坛',
  FLT: '分论坛'
};
export var LUNBO_TYPE = {
  METTING: 0,
  CAURSE: 1,
  CONTATUS: 2,
  SAEIESMEETING: 3,
  FORUM: 4
};
export var QUESTION_TYPE = {
  RADIO: 0,
  CHECKBOX: 1,
  FILL: 3
};
export var CAURSE_TYPE = {
  TRAIN: 0,
  LINGHT: 1
};
export var MEETING_TYPE = {
  INOUT: 0,
  IN: 1,
  OUT: 2
};
export var MEETING_STATUS = {
  WAITING: 0,
  DOING: 1,
  FINISHED: 2
};
export var QUESTION_TYPE_STATUS = {
  QUESTIONNAIRE: 0,
  EXAMINATION: 1
};
export var LEARN_TYPE = {
  CONTATUS: 0,
  METTING: 1,
  CAURSE: 2,
  SAEIESMEETING: 3
};
export var SEARCH_TYPE = {
  EXPERT: 'Expert',
  METTING: 'Meeting',
  CAURSE: 'Course',
  CONTATUS: 'Article',
  LESSON: 'Lesson'
};
export var SEX_TYPE = {
  MAN: 0,
  WOMEN: 1
};
export var SEX_TYPE_LIST = [{
  key: '男',
  value: 0
}, {
  key: '女',
  value: 1
}];

//待审核 已通过 待开始 进行中 已驳回 已取消  已完成
export var CONSULATION_STATUS_TYPE = {
  AUDIT: 0,
  PASS: 1,
  TOSTART: 2,
  PROCESSING: 3,
  REJECT: 4,
  CANCEL: 5,
  COMPLETE: 6,
  FINISH: 7
};
export var CONSULATION_STATUS_TYPE_LIST = [{
  key: '待审核',
  value: 0
}, {
  key: '已通过',
  value: 1
}, {
  key: '待开始',
  value: 2
}, {
  key: '进行中',
  value: 3
}, {
  key: '已驳回',
  value: 4
}, {
  key: '已取消',
  value: 5
}, {
  key: '已完成',
  value: 6
}, {
  key: '已结束',
  value: 7
}];