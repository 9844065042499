import "core-js/modules/es.array.push.js";
export default {
  name: 'home',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      // 点赞拖拽
      homemgrState: false,
      homemousedownState: false,
      //鼠标默认抬起
      homeiX: 0,
      //鼠标坐标 与 拖拽按钮 间距 x
      homeiY: 0 //鼠标坐标 与 拖拽按钮 间距 y
    };
  },

  methods: {
    onmousedownhome: function onmousedownhome(event) {
      /* 此处判断  pc 或 移动端 得到 event 事件 */
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      // 鼠标点击 面向页面 的 x坐标 y坐标
      var _touch = touch,
        clientX = _touch.clientX,
        clientY = _touch.clientY;
      // 鼠标x坐标 - 拖拽按钮x坐标  得到鼠标 距离 拖拽按钮 的间距
      this.homeiX = clientX - this.$refs.functionshome.offsetLeft;
      // 鼠标y坐标 - 拖拽按钮y坐标  得到鼠标 距离 拖拽按钮 的间距
      this.homeiY = clientY - this.$refs.functionshome.offsetTop;
      // 设置当前 状态为 鼠标按下
      this.homemousedownState = true;
    },
    onmousemovehome: function onmousemovehome(event) {
      //鼠标按下 切移动中
      if (this.homemousedownState) {
        /* 此处判断  pc 或 移动端 得到 event 事件 */
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        // 鼠标移动时 面向页面 的 x坐标 y坐标
        var _touch2 = touch,
          clientX = _touch2.clientX,
          clientY = _touch2.clientY;
        //当前主页面全局容器 dom 元素  获取容器 宽高
        var app = document.getElementById('app');
        var appDivY = app.clientHeight;
        //当前页面全局容器 dom 元素  获取容器 宽高
        var interactionArea = document.getElementById(this.id);
        var pageDivY = interactionArea.clientHeight,
          pageDivX = interactionArea.clientWidth;
        /* 鼠标坐标 - 鼠标与拖拽按钮的 间距坐标  得到 拖拽按钮的 左上角 x轴y轴坐标 */
        var x = clientX - this.homeiX,
          y = clientY - this.homeiY;
        //拖拽按钮 dom 元素  获取 宽高 style 对象
        var _this$$refs$functions = this.$refs.functionshome,
          actionMgrY = _this$$refs$functions.clientHeight,
          actionMgrX = _this$$refs$functions.clientWidth,
          actionMgrStyle = _this$$refs$functions.style;
        /* 此处判断 拖拽按钮 如果超出 屏幕宽高 或者 小于
           设置 屏幕最大 x=全局容器x y=全局容器y 否则 设置 为 x=0 y=0
        */
        if (x > pageDivX - actionMgrX) x = pageDivX - actionMgrX;else if (x < 0) x = 0;
        if (y > appDivY - actionMgrY) y = appDivY - actionMgrY;else if (y < appDivY - pageDivY) y = appDivY - pageDivY;
        // 计算后坐标  设置 按钮位置
        actionMgrStyle.left = "".concat(x, "px");
        actionMgrStyle.top = "".concat(y, "px");
        actionMgrStyle.bottom = 'auto';
        actionMgrStyle.right = 'auto';
        //当按下键滑动时， 阻止屏幕滑动事件
        event.preventDefault();
      }
    },
    onmouseuphome: function onmouseuphome(event) {
      // 设置当前状态为鼠标抬起
      this.homemousedownState = false;
    },
    handleGoBack: function handleGoBack() {
      this.$router.push({
        path: '/home'
      });
    }
  }
};