import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.string.match.js";
/**
 * Created by Sunnie on 19/06/04.
 */

import { isNull } from "lodash";

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/* 手机号码*/
export function validateMobilePhone(str) {
  var reg = /^(0|86|17951)?(13[0-9]|15[0-9]|166|19[0-9]|17[0-9]|18[0-9]|14[57])[0-9]{8}$/;
  return reg.test(str);
}

/**
 * @param {Object} obj
 * @returns {Boolean}
 */
export function isEmpty(obj) {
  if (obj == null || obj == undefined || obj == '') {
    return true;
  }
  return false;
}

// 是否手机端
export function isMobile() {
  var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}

// 是否ios
export function isIos() {
  var u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && u.indexOf('iPhone') > -1;
}
export function isAndroid() {
  var u = navigator.userAgent;
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    return true;
  }
  return false;
}

// 判断是否苹果浏览器
export function isSafari() {
  if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent) && !/MQQBrowser/.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

// 是否微信浏览器
export function isWx() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

// 检查是否完善信息
export function checkUserSetting(user) {
  return isNull(user) || !isEmpty(user.Hospital) || !isEmpty(user.Department) || !isEmpty(user.Title) || !isEmpty(user.JobTitle) || !isEmpty(user.Major) || !isEmpty(user.Education) || !isEmpty(user.EntroYear) || !isEmpty(user.Enterprise) || !isEmpty(user.School) || !isEmpty(user.EnterpriseDepartment) || !isEmpty(user.EnterpriseTitle);
}