import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/datetime-picker/style";
import _DatetimePicker from "vant/es/datetime-picker";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/progress/style";
import _Progress from "vant/es/progress";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";
// 按需全局引入 vant组件
import Vue from 'vue';
Vue.use(_Button);
Vue.use(_Cell);
Vue.use(_List);
Vue.use(_Tabbar).use(_TabbarItem);
Vue.use(_Search);
Vue.use(_Icon);
Vue.use(_Tabs).use(_Tab);
Vue.use(_Image);
Vue.use(_Loading);
Vue.use(_Overlay);
Vue.use(_Field);
Vue.use(_Uploader);
Vue.use(_Form);
Vue.use(_PullRefresh);
Vue.use(_Swipe).use(_SwipeItem);
Vue.use(_Lazyload);
Vue.use(_ActionSheet);
Vue.use(_RadioGroup).use(_Radio);
Vue.use(_CheckboxGroup).use(_Checkbox);
Vue.use(_Progress);
Vue.use(_CellGroup);
Vue.use(_Empty);
Vue.use(_Popup);
Vue.use(_DatetimePicker);
Vue.use(_Area);
Vue.use(_Picker);
Vue.use(_Dialog);