import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import axios from 'axios';
import store from '@/store';
import router from '@/router';
// 根据环境不同引入不同api地址
import { baseApi } from '@/config';
import Cookie from 'js-cookie';
import { userKey } from '@/config';
import { logout } from '@/api/user';

// 跨域认证信息 header 名
var xsrfHeaderName = 'Authorization';
var xsrfHeaderName_2 = '.AspNetCore.Identity.Application';

// create an axios instance
var service = axios.create({
  baseURL: baseApi,
  // url = base api url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 50000000000000 // request timeout
  // xsrfHeaderName: xsrfHeaderName,
  // xsrfCookieName: xsrfHeaderName
});

// 认证类型
var AUTH_TYPE = {
  BEARER: 'Bearer',
  BASIC: 'basic',
  AUTH1: 'auth1',
  AUTH2: 'auth2'
};

/**
 * 设置认证信息
 * @param auth {Object}
 * @param authType {AUTH_TYPE} 认证类型，默认：{AUTH_TYPE.BEARER}
 */
function setAuthorization(auth) {
  var authType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : AUTH_TYPE.BEARER;
  switch (authType) {
    case AUTH_TYPE.BEARER:
      Cookie.set(xsrfHeaderName, auth.token, {
        expires: auth.expireAt
      });
      break;
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break;
  }
}

/**
 * 移出认证信息
 * @param authType {AUTH_TYPE} 认证类型
 */
function removeAuthorization() {
  var authType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : AUTH_TYPE.BEARER;
  switch (authType) {
    case AUTH_TYPE.BEARER:
      Cookie.remove(xsrfHeaderName);
      Cookie.remove(xsrfHeaderName_2);
      break;
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break;
  }
}

/**
 * 检查认证信息
 * @param authType
 * @returns {boolean}
 */
function checkAuthorization() {
  var authType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : AUTH_TYPE.BEARER;
  switch (authType) {
    case AUTH_TYPE.BEARER:
      if (Cookie.get(xsrfHeaderName) || Cookie.get(xsrfHeaderName_2)) {
        return true;
      }
      break;
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break;
  }
  return false;
}

/**
 * 设置认证信息
 * @param auth {Object}
 * @param authType {AUTH_TYPE} 认证类型，默认：{AUTH_TYPE.BEARER}
 */
function getAuthorization() {
  var authType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : AUTH_TYPE.BEARER;
  switch (authType) {
    case AUTH_TYPE.BEARER:
      return Cookie.get(xsrfHeaderName).replace('Bearer ', '');
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break;
  }
}
var noAuthLiveStr = 'https://liveplay.yidao.pro/live';

// request拦截器 request interceptor
service.interceptors.request.use(function (config) {
  var shortUrl = config.url;
  if (shortUrl.indexOf(noAuthLiveStr) < 0 && checkAuthorization()) {
    config.headers['Authorization'] = 'Bearer ' + getAuthorization();
  }
  // if (!checkAuthorization() && shortUrl.indexOf("Account") < 0) {
  //   router.push({ path: '/login' })
  //   return config
  // }
  // 不传递默认开启loading
  if (!config.hideloading) {
    // // loading
    // Toast.loading({
    //   forbidClick: true
    // })
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});
// respone拦截器
service.interceptors.response.use(function (response) {
  _Toast.clear();
  var res = response.data;
  if (res.status && res.status !== 200) {
    // 登录超时,重新登录
    if (res.status === 401) {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    }
    return Promise.reject(res || 'error');
  } else {
    return Promise.resolve(res);
  }
}, function (error) {
  if (error.response.status === 401) {
    // debugger
    // store.dispatch('FedLogOut').then(() => {
    //   location.reload()
    // })
    // console.log(router)
    // debugger
    logout();
    router.push({
      path: '/plogin'
    });

    // localStorage.removeItem(userKey)
    // removeAuthorization()
    // location.reload()
  }
  // debugger
  // if (error.response.headers['securitytokenexpired'] == 'expired') {
  //   localStorage.removeItem(userKey)
  //   removeAuthorization()
  // }
  _Toast.clear();
  console.log('err' + error); // for debug
  return Promise.reject(error);
});
export default service;
export { AUTH_TYPE, service, getAuthorization, setAuthorization, removeAuthorization, checkAuthorization };