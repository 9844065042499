// axios
import request from '@/utils/request';

// 根据type获取资讯或通告
export function queryArticle(type, pageSize, page) {
  return request({
    url: "/api/v1/Article/Query",
    method: 'get',
    params: {
      type: type,
      pageSize: pageSize,
      page: page
    }
  });
}

// 根据type获取资讯或通告
export function getArticleInfo(id) {
  return request({
    url: "/api/v1/Article/Get",
    method: 'get',
    params: {
      id: id
    }
  });
}

// 查询
export function Search(name, type, pageSize, page) {
  return request({
    url: "/api/v1/Search/Search",
    method: 'get',
    params: {
      s: name,
      type: type,
      pageSize: pageSize,
      page: page
    }
  });
}

// 增加浏览量
export function AddViewCount(id) {
  return request({
    url: "/api/v1/Article/AddViewCount",
    method: 'get',
    params: {
      id: id
    }
  });
}