// axios
import request from '@/utils/request';

// 通过二维码邀约当前扫描用户
export function InviteUser(meetingId, inviter) {
  return request({
    url: "/api/Invitation/InviteUser",
    method: 'post',
    data: {
      meetingId: meetingId,
      inviter: inviter
    }
  });
}

//获取邀约排行
export function getTopList(meetingId, pageSize, IsSelfInfo) {
  return request({
    url: "/api/Invitation/GetTopList",
    method: 'get',
    params: {
      meetingId: meetingId,
      pageSize: pageSize,
      IsSelfInfo: IsSelfInfo
    }
  });
}

// 是否第一进入直播间
export function isNewUser(StreamId) {
  return request({
    url: "/api/Invitation/IsNewUser",
    method: 'get',
    params: {
      StreamId: StreamId
    }
  });
}