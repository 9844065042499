import Vue from 'vue';
export default (function () {
  Vue.directive('scroll-bottom', {
    bind: function bind(el, binding) {
      var scrollPosition = 0; // 上次滚动位置
      // 注册滚动事件
      el.addEventListener('scroll', function () {
        var clientHeight = this.clientHeight,
          scrollTop = this.scrollTop,
          scrollHeight = this.scrollHeight;
        var scrollBottom = scrollHeight - scrollTop - clientHeight;
        if (scrollTop > scrollPosition && scrollBottom <= 48) {
          // 向下滚动时触发加载事件
          binding.value();
        }
        scrollPosition = scrollTop;
      });
    }
  });
});