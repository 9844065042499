// axios
import request from '@/utils/request';

// 根据fileId 拿到对应文件
export function getFilesFromCourse(fileId) {
  return request({
    url: "/api/v1/Course/GetFilesFromCourse",
    method: 'get',
    params: {
      fileId: fileId
    }
  });
}

// 报名课程
export function signInCourse(data) {
  return request({
    url: "/api/v1/Course/SignInCourse",
    method: 'post',
    params: data
  });
}

// 是否报名课程
export function checkSignInStatus(courseId) {
  return request({
    url: "/api/v1/Course/CheckSignInStatus",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 查询培训课
export function QueryPx(pageSize, page, type, isDone) {
  return request({
    url: "/api/v1/Course/QueryPx",
    method: 'get',
    params: {
      pageSize: pageSize,
      page: page,
      type: type,
      isDone: isDone
    }
  });
}

// 发送留言
export function SendCaurseComment(courseId, content, parentId) {
  return request({
    url: "/api/v1/Course/SendComment",
    method: 'post',
    data: {
      courseId: courseId,
      content: content,
      parentId: parentId
    }
  });
}

// 获取留言
export function GetPassComment(pageSize, page, courseId, parentId) {
  return request({
    url: "/api/v1/Course/GetCommentList",
    method: 'get',
    params: {
      pageSize: pageSize,
      page: page,
      courseId: courseId,
      parentId: parentId
    }
  });
}

// 获取单个课程详情
export function getCourse(courseId) {
  return request({
    url: "/api/v1/Course/GetCourse",
    method: 'get',
    params: {
      id: courseId
    }
  });
}

// 增加浏览量
export function AddCaurseViewCount(courseId) {
  return request({
    url: "/api/v1/Course/AddCourseViewCount",
    method: 'post',
    params: {
      courseId: courseId
    }
  });
}

// 获取浏览量
export function GetCaurseViewCount(courseId) {
  return request({
    url: "/api/v1/Course/GetCourseViewCount",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 获取课程的总浏览量
export function getAllFavoriteAndViewCount(courseId) {
  return request({
    url: "/api/v1/Course/GetAllFavoriteAndViewCount",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 添加课程学习数据
export function initLearnData(courseId) {
  return request({
    url: "/api/v1/Course/InitLearnData",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 根据课程id 获取当前登录用户的学习目录以及时长
export function getDirectoryList(courseId) {
  return request({
    url: "/api/v1/Course/GetDirectoryList",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 增加改用户的目录观看时长以及进度条最大时长
export function addDirectoryMemory(courseDirectoryId, viewTimes, lastTimes) {
  return request({
    url: "/api/v1/Course/AddDirectoryMemory",
    method: 'post',
    data: {
      courseDirectoryId: courseDirectoryId,
      viewTimes: viewTimes,
      lastTimes: lastTimes
    }
  });
}

// 获取总评论数
export function getAllCommentCount(courseId) {
  return request({
    url: "/api/v1/Course/GetAllCommentCount",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 获取上次用户观看的目录id
export function getUserWatchRecord(courseId) {
  return request({
    url: "/api/v1/Account/GetUserWatchRecord",
    method: 'get',
    params: {
      courseId: courseId
    }
  });
}

// 保存上次用户观看记录
export function addUserWatchRecord(videoId, courseId) {
  return request({
    url: "/api/v1/Account/AddUserWatchRecord",
    method: 'post',
    params: {
      videoId: videoId,
      courseId: courseId
    }
  });
}