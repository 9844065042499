import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.concat.js";
import moment from 'moment';
import { GetJsApiConfig } from '@/api/user';
import { getArticleInfo } from '@/api/article';
import { getMeeting } from '@/api/meeting';
import { getCourse } from '@/api/course';
import { getForumInfo } from '@/api/forum';
import { isIos } from '@/utils/validate';
// import wx from 'weixin-jsapi'
import store from '@/store/index';
import { imagePrefix, weixinImage } from '@/config';
import { checkAuthorization } from '@/utils/request';
import { isEmpty, isNull } from 'lodash';
var THOUSAND = 1000;
var TEN_THOUSAND = 10000;
var NAME = process.env.PROJECT_NAME;
/**
 *格式化时间
 *yyyy-MM-dd hh:mm:ss
 */
export function formatDate(time, fmt) {
  if (time === undefined || '') {
    return;
  }
  var date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

/*
 * 隐藏用户手机号中间四位
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

/*
 * Utc 转 北京时间
 */
export function formatUtc(time, fmt) {
  return moment.utc(time).utcOffset(8).format(fmt);
}

/*
 * Utc 转 北京时间
 */
export function formatUtcTime(time) {
  return moment.utc(time).utcOffset(8);
}

/*
 * Utc 转 北京时间
 */
export function formatUtcMilliseconds(time) {
  return moment.utc(time).utcOffset(8).format('x');
}

/**
 * UTC 与 当前时间 比较
 */
export function diffUtc(time) {
  return moment(time).utcOffset(8).diff(moment(), 'minutes');
}

/**
 * UTC 与 当前时间 比较
 */
export function getHourMin(time) {
  var hours = moment.duration(time * 1000).hours();
  var minutes = moment.duration(time * 1000).minutes();
  var seconds = moment.duration(time * 1000).seconds();
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
}

/**
 * 数量格式化，如3.5k
 * value: 待格式化数字
 */
export function formatCount(value) {
  if (value > 0 && value < THOUSAND) {
    return value;
  } else if (value >= THOUSAND && value < TEN_THOUSAND) {
    return (value / THOUSAND).toFixed(1) + 'k';
  } else if (value >= TEN_THOUSAND) {
    return (value / TEN_THOUSAND).toFixed(1) + 'w';
  }
  return '0';
}

/**
 * 微信分享
 */
export function weixinShare() {
  var link = '';
  var weixin = {
    title: '',
    desc: '',
    link: '',
    imgUrl: ''
  };
  var path = this.$route.path;
  var query = this.$route.query;
  if (isIos()) {
    link = window.entryUrl;
  } else {
    if (window.location.href.indexOf('from') != -1 || window.location.href.indexOf('isappinstalled') != -1) {
      // 二次分享url重定向 - 需要截取一次分享后微信自动拼接的url参数&from=singlemessage&isappinstalled=0
      window.location.href = window.location.href.split('&')[0];
    }
    link = window.location.href;
  }

  // 固定写死的页面
  weixin = convertConstData(path, link);
  if (path == '/consultDetails') {
    var articleId = query.consultId;
    getArticleInfo(articleId).then(function (data) {
      weixin = {
        title: isNull(data.Share) ? data.Title : isEmpty(data.Share.Name) ? data.Title : data.Share.Name,
        desc: isNull(data.Share) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.Share.Description) ? '邀请你一起学习，赶快加入吧' : data.Share.Description,
        link: link,
        imgUrl: isNull(data.Share) ? imagePrefix + data.Image : isEmpty(data.Share.FileId) ? imagePrefix + data.Image : imagePrefix + data.Share.FileId
      };
      weixinApiConfig(weixin);
    });
  } else if (path == '/meetDetails') {
    var meetId = query.meetId;
    getMeeting(meetId).then(function (data) {
      weixin = {
        title: isNull(data.Share) ? data.Name : isEmpty(data.Share.Name) ? data.Name : data.Share.Name,
        desc: isNull(data.Share) ? isNull(data.MeetingInfo) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.MeetingInfo.MeetingDescription) ? '邀请你一起学习，赶快加入吧' : data.MeetingInfo.MeetingDescription : isEmpty(data.Share.Description) ? isNull(data.MeetingInfo) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.MeetingInfo.MeetingDescription) ? '邀请你一起学习，赶快加入吧' : data.MeetingInfo.MeetingDescription : data.Share.Description,
        link: link,
        imgUrl: isNull(data.Share) ? imagePrefix + data.Image : isEmpty(data.Share.FileId) ? imagePrefix + data.Image : imagePrefix + data.Share.FileId
      };
      weixinApiConfig(weixin);
    });
  } else if (path == '/caurseDetails') {
    var caurseId = query.caurseId;
    getCourse(caurseId).then(function (data) {
      weixin = {
        title: isNull(data.Share) ? data.Name : isEmpty(data.Share.Name) ? data.Name : data.Share.Name,
        desc: isNull(data.Share) ? isEmpty(data.CourseDesc) ? '邀请你一起学习，赶快加入吧' : data.CourseDesc : isEmpty(data.Share.Description) ? isEmpty(data.CourseDesc) ? '邀请你一起学习，赶快加入吧' : data.CourseDesc : data.Share.Description,
        link: link,
        imgUrl: isNull(data.Share) ? imagePrefix + data.ThumImg : isEmpty(data.Share.FileId) ? imagePrefix + data.ThumImg : imagePrefix + data.Share.FileId
      };
      weixinApiConfig(weixin);
    });
  } else if (path == '/smeetDetails') {
    var smeetId = query.smeetId;
    getMeeting(smeetId).then(function (data) {
      weixin = {
        title: isNull(data.Share) ? data.Name : isEmpty(data.Share.Name) ? data.Name : data.Share.Name,
        desc: isNull(data.Share) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.Share.Description) ? isNull(data.MeetingInfo) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.MeetingInfo.MeetingDescription) ? '邀请你一起学习，赶快加入吧' : data.MeetingInfo.MeetingDescription : data.Share.Description,
        link: link,
        imgUrl: isNull(data.Share) ? imagePrefix + data.Image : isEmpty(data.Share.FileId) ? imagePrefix + data.Image : imagePrefix + data.Share.FileId
      };
      weixinApiConfig(weixin);
    });
  } else if (path == '/forum' || path == '/forumDetail') {
    var fid = query.fid;
    // getForumInfo(fid).then(data => {
    var data = JSON.parse(sessionStorage.getItem('forumInfo'));
    if (data) {
      weixin = {
        title: isNull(data.Basic) ? data.Name : isEmpty(data.Basic.ShareName) ? data.Name : data.Basic.ShareName,
        desc: isNull(data.Basic) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.Basic.ShareDescription) ? '邀请你一起学习，赶快加入吧' : data.Basic.ShareDescription,
        link: link,
        imgUrl: isNull(data.Basic) ? '' : isEmpty(data.Basic.ShareBackgoundImage) ? imagePrefix + data.Basic.InnerThumbnail[0] : imagePrefix + data.Basic.ShareBackgoundImage
        // 图片地址错误
        // imgUrl: isNull(data.Basic)
        //   ? ''
        //   : isEmpty(data.Basic.ShareBackgoundImage)
        //   ? data.Basic.InnerThumbnail[0]
        //   : data.Basic.ShareBackgoundImage
      };

      weixinApiConfig(weixin);
    } else {
      getForumInfo(fid).then(function (data) {
        sessionStorage.setItem('forumInfo', JSON.stringify(data));
        weixin = {
          title: isNull(data.Basic) ? data.Name : isEmpty(data.Basic.ShareName) ? data.Name : data.Basic.ShareName,
          desc: isNull(data.Basic) ? '邀请你一起学习，赶快加入吧' : isEmpty(data.Basic.ShareDescription) ? '邀请你一起学习，赶快加入吧' : data.Basic.ShareDescription,
          link: link,
          imgUrl: isNull(data.Basic) ? '' : isEmpty(data.Basic.ShareBackgoundImage) ? imagePrefix + data.Basic.InnerThumbnail[0] : imagePrefix + data.Basic.ShareBackgoundImage
          // 图片地址错误
          // imgUrl: isNull(data.Basic)
          //   ? ''
          //   : isEmpty(data.Basic.ShareBackgoundImage)
          //   ? data.Basic.InnerThumbnail[0]
          //   : data.Basic.ShareBackgoundImage
        };

        weixinApiConfig(weixin);
      });
    }
    // })
  } else {
    weixinApiConfig(weixin);
  }
}
function weixinApiConfig(weixin) {
  console.log(wx);
  GetJsApiConfig(weixin.link).then(function (res) {
    wx.config({
      debug: false,
      // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.appId,
      // 必填，公众号的唯一标识
      timestamp: res.timestamp,
      // 必填，生成签名的时间戳
      nonceStr: res.nonceStr,
      // 必填，生成签名的随机串
      signature: res.signature,
      // 必填，签名，见附录1
      jsApiList: ['onMenuShareTimeline', 'updateTimelineShareData', 'onMenuShareAppMessage', 'updateAppMessageShareData']
    });
    wx.checkJsApi({
      jsApiList: [
      // 所有要调用的 API 都要加到这个列表中
      'onMenuShareTimeline', 'onMenuShareAppMessage', 'updateTimelineShareData',
      // 分享到朋友圈接口
      'updateAppMessageShareData',
      //  分享到朋友接口
      'checkJsApi'],
      success: function success(res) {
        console.log('成功');
      },
      error: function error(res) {
        console.log('失败');
      }
    });
    wx.ready(function () {
      // //分享到朋友圈
      wx.updateTimelineShareData({
        title: weixin.title,
        // 分享时的标题
        link: weixin.link,
        // 分享时的链接
        imgUrl: weixin.imgUrl,
        success: function success() {
          console.log('分享成功');
        },
        cancel: function cancel() {
          console.log('取消分享');
        },
        error: function error(res) {
          console.log('分享失败');
        }
      });

      //分享给朋友
      wx.updateAppMessageShareData({
        title: weixin.title,
        desc: weixin.desc,
        link: weixin.link,
        imgUrl: weixin.imgUrl,
        success: function success(res) {
          console.log('成功');
        },
        error: function error(res) {
          console.log('分享失败');
        }
      });
      wx.error(function (res) {
        console.log(res.errMsg);
      });

      //分享给朋友
      wx.onMenuShareAppMessage({
        title: weixin.title,
        desc: weixin.desc,
        link: weixin.link,
        imgUrl: weixin.imgUrl,
        success: function success(res) {
          console.log('分享成功');
        },
        cancel: function cancel() {
          console.log('取消分享');
        },
        error: function error(res) {
          console.log('分享失败');
        }
      });
      wx.error(function (res) {
        console.log(res.errMsg);
      });
    });
  }).catch();
}
function convertConstData(path, link) {
  if (checkAuthorization()) {
    var user = store.getters['account/user'];
    return {
      title: "".concat(path == '/home' ? '首页-' : path == '/consult' ? '最新资讯-' : path == '/meeting' ? '最新会议-' : path == '/caurse' ? '课程培训-' : path == '/center' ? '个人中心-' : '').concat(NAME),
      desc: "".concat(user.NickName, "\u9080\u8BF7\u4F60\u4E00\u8D77\u5B66\u4E60\uFF0C\u8D76\u5FEB\u52A0\u5165\u5427"),
      link: link,
      imgUrl: imagePrefix + weixinImage
    };
  } else {
    return {
      title: "".concat(path == '/home' ? '首页-' : path == '/consult' ? '最新资讯-' : path == '/meeting' ? '最新会议-' : path == '/caurse' ? '课程培训-' : path == '/center' ? '个人中心-' : '').concat(NAME),
      desc: "\u9080\u8BF7\u4F60\u4E00\u8D77\u5B66\u4E60\uFF0C\u8D76\u5FEB\u52A0\u5165\u5427",
      link: link,
      imgUrl: imagePrefix + weixinImage
    };
  }
}