import "/src/node_modules/core-js/modules/es.array.iterator.js";
import "/src/node_modules/core-js/modules/es.promise.js";
import "/src/node_modules/core-js/modules/es.object.assign.js";
import "/src/node_modules/core-js/modules/es.promise.finally.js";
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 设置 js中可以访问 $cdn
import { $cdn } from '@/config';
Vue.prototype.$cdn = $cdn;

// 全局引入按需引入UI库 vant
import '@/plugins/vant';
// 引入全局样式
import '@/assets/css/index.scss';
// 移动端适配
import 'lib-flexible/flexible.js';

// filters
import './filters';
Vue.config.productionTip = false;

// video
import VideoPlayer from 'vue-video-player';
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
require('@/assets/style/video.css');
Vue.use(VideoPlayer);

//滚动条
import VueVirtualScroller from 'vue-virtual-scroller';
import '@/assets/style/vue-virtual-scroller.css';
Vue.use(VueVirtualScroller);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {
  size: 'medium'
});
import mandMobile from 'mand-mobile';
import 'mand-mobile/lib/mand-mobile.css';
Vue.use(mandMobile);
import Directives from './directives';
Vue.use(Directives);

// 富文本样式
import '@/assets/css/ql-editor/vue2-editor.css';
import '@/assets/css/ql-editor/quill.core.css';
import '@/assets/css/ql-editor/quill.bubble.css';
import '@/assets/css/ql-editor/quill.snow.css';
import 'moment/locale/zh-cn';
import './api/wechat';
// import share from './share'
// Vue.use(share)

new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});