module.exports = {
  title: '国家传染病医学中心',
  baseApi: 'https://test-national-api.medstudy.pro/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  imagePrefix: 'https://test-national-api.medstudy.pro/api/File/GetFileContent/',
  cdnImagePrefix: 'https://cdn-national.yidao.pro/',
  codeUrl: "https://test-national.medstudy.pro",
  connection_url: "https://test-national-api.medstudy.pro/hub/ls-interact",
  userKey: "admin.user",
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://www.sunniejs.cn/static'
};