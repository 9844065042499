import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    ref: "functionscustomer",
    staticClass: "customer",
    on: {
      touchstart: _vm.onmousedowncustomer,
      touchmove: _vm.onmousemovecustomer,
      touchend: _vm.onmouseupcustomer
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/customer.png")
    },
    on: {
      click: _vm.handleCustomer
    }
  }), _c("div", {
    staticClass: "customer-name"
  }, [_vm._v("客服")])]), _c("van-action-sheet", {
    staticClass: "vanActionSheet",
    scopedSlots: _vm._u([{
      key: "description",
      fn: function fn() {
        return [_c("img", {
          staticClass: "action-image",
          attrs: {
            src: require("@/assets/images/customer-title.png")
          }
        }), _c("div", [_vm._v("客服助手")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openActionSheet,
      callback: function callback($$v) {
        _vm.openActionSheet = $$v;
      },
      expression: "openActionSheet"
    }
  }, [_c("van-form", {
    ref: "vanActionSheet",
    staticClass: "action-form",
    attrs: {
      "validate-first": ""
    },
    on: {
      submit: _vm.submitActionSheet
    }
  }, [_c("van-field", {
    attrs: {
      name: "name",
      label: "姓名",
      placeholder: "请输入姓名",
      rules: [{
        required: true,
        message: "请填写姓名"
      }],
      formatter: _vm.formatterEmpty
    },
    model: {
      value: _vm.customer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "name", $$v);
      },
      expression: "customer.name"
    }
  }), _c("van-field", {
    attrs: {
      name: "phone",
      label: "联系方式",
      type: "tel",
      placeholder: "请输入联系方式",
      rules: [{
        required: true,
        message: "请填写联系方式"
      }, {
        validator: _vm.validatePhone,
        message: "请输入正确的手机号"
      }]
    },
    model: {
      value: _vm.customer.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "phone", $$v);
      },
      expression: "customer.phone"
    }
  }), _c("van-field", {
    attrs: {
      name: "content",
      label: "留言",
      type: "textarea",
      maxlength: "100",
      "show-word-limit": "",
      autosize: "",
      placeholder: "请简要描述您遇到的问题",
      rules: [{
        required: true,
        message: "请简要描述您遇到的问题"
      }],
      formatter: _vm.formatterEmpty
    },
    model: {
      value: _vm.customer.content,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "content", $$v);
      },
      expression: "customer.content"
    }
  }), _c("div", {
    staticClass: "action-btn"
  }, [_c("van-button", {
    staticClass: "action-btn-item",
    attrs: {
      type: "info",
      "native-type": "submit"
    }
  }, [_vm._v(" 确认 ")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };