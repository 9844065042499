var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.network ? _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("van-empty", {
    attrs: {
      image: "network",
      description: "连接不到您的网络，请检查网络后点击页面刷新！"
    }
  })], 1) : _c("div", {
    attrs: {
      id: "app"
    }
  }, [_vm.$route.meta.keepAlive ? _c("keep-alive", [_c("router-view")], 1) : _c("router-view"), _vm.$route.meta.customer ? _c("Customer", {
    attrs: {
      id: "app"
    }
  }) : _vm._e(), _vm.$route.meta.home ? _c("GoBack", {
    attrs: {
      id: "app"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };