import _createForOfIteratorHelper from "/src/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import request from '@/utils/request';
export function getForumInfo(id) {
  return request({
    url: '/api/mgt/BBS/GetBBS',
    method: 'get',
    params: {
      Id: id
    }
  });
}
// meet
export function getMeetList(midList) {
  // let url = '/api/v1/Meeting/GetMany?'
  // for (let item of midList) {
  //   url += `MeetingIds=${item}&`
  // }
  // url = url.replace(/[?&]$/, '')
  var url = '/api/v1/Meeting/GetMany';
  return request({
    url: url,
    method: 'post',
    data: midList
  });
}
// course
export function getCourseList(cidList) {
  var url = '/api/v1/Course/GetMany?';
  var _iterator = _createForOfIteratorHelper(cidList),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      url += "Ids=".concat(item, "&");
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  url = url.replace(/[?&]$/, '');
  return request({
    url: url,
    method: 'get'
  });
}
// expert
export function getExpertList(eidList) {
  var url = '/api/v1/Meeting/GetExpertMany?';
  var _iterator2 = _createForOfIteratorHelper(eidList),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var item = _step2.value;
      url += "MeetingIds=".concat(item, "&");
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  url = url.replace(/[?&]$/, '');
  return request({
    url: url,
    method: 'get'
  });
}
// artical
export function getInfoList(iidList) {
  var url = '/api/v1/Article/GetMany?';
  var _iterator3 = _createForOfIteratorHelper(iidList),
    _step3;
  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var item = _step3.value;
      url += "Ids=".concat(item, "&");
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
  url = url.replace(/[?&]$/, '');
  return request({
    url: url,
    method: 'get'
  });
}
// question
export function getQuestionList(qidList) {
  var url = '/api/v1/QuestionNaire/GetManyQuestionnaire?';
  var _iterator4 = _createForOfIteratorHelper(qidList),
    _step4;
  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var item = _step4.value;
      url += "QnIds=".concat(item, "&");
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }
  url = url.replace(/[?&]$/, '');
  return request({
    url: url,
    method: 'get'
  });
}
// file
export function getFileImgList(fidList) {
  var url = '/api/File/GetFileMany?';
  var _iterator5 = _createForOfIteratorHelper(fidList),
    _step5;
  try {
    for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
      var item = _step5.value;
      url += "ids=".concat(item, "&");
    }
  } catch (err) {
    _iterator5.e(err);
  } finally {
    _iterator5.f();
  }
  url = url.replace(/[?&]$/, '');
  return request({
    url: url,
    method: 'get'
  });
}
// 论坛模块的点击量

export function addModelClickCount(ModuleId, BBSId) {
  var url = 'api/mgt/BBS/IncreaseClickCount';
  return request({
    url: url,
    method: 'get',
    params: {
      ModuleId: ModuleId,
      BBSId: BBSId
    }
  });
}