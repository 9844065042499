import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import Vue from 'vue';
import Router from 'vue-router';
import { constantRouterMap } from './router.config.js';
import { isEmpty, isWx, checkUserSetting } from '@/utils/validate';
import { GetWxOpenId, getTokenByOpenId, getUserInfo, checkSubscribe, logout } from '@/api/user';
import { isNewUser, InviteUser } from '@/api/invitation';
import { setAuthorization, checkAuthorization } from '@/utils/request';
import { CERT_TYPE } from '@/utils/constant';
import store from '@/store/index';
// hack router push callback
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
Vue.use(Router);
var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // 如果你是 history模式 需要配置vue.config.js publicPath
    // base: process.env.BASE_URL,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRouterMap
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

router.beforeEach(function (to, from, next) {
  // debugger
  if (to.name == 'forum') {
    window.document.title = ' ';
  } else {
    window.document.title = to.meta.title;
  }
  var weixinCode = to.query.code || weixinCode;
  var weixinState = to.query.state || weixinState;
  var weixinType = to.query.weixinType || weixinType;
  var meetingId = to.query.meetingId || meetingId;
  var userId = to.query.userId || userId || '';
  var currentTab = to.query.currentTab;
  var returnUrl = to.query.returnUrl || '';
  // const toCert = to.query.toCert || false
  var getUser = store.getters['account/user'];
  if (checkAuthorization() && !checkUserSetting(getUser)) {
    // if (checkAuthorization() && false) {
    if (to.name !== 'Improve') {
      next({
        path: '/improve',
        query: to.query,
        replace: true
      });
    } else {
      next();
    }
    // } else if (!isEmpty(weixinCode) && !isEmpty(weixinState) && !isEmpty(weixinType) && isWx()) {
  } else if (!isEmpty(weixinState) && isWx()) {
    // if (weixinType == 1) {
    if (weixinState == 'not_registred') {
      next({
        path: '/plogin',
        query: {
          isWx: true,
          result: JSON.stringify(wx),
          meetingId: meetingId,
          userId: userId,
          returnUrl: returnUrl,
          currentTab: currentTab,
          state: 'registred'
        },
        replace: true
      });

      // debugger
      // GetWxOpenId(weixinCode, weixinState).then(wx => {
      //   getTokenByOpenId(wx.openid).then(result => {
      //     if (isEmpty(result)) {
      //       next({
      //         path: '/plogin',
      //         query: { isWx: true, result: JSON.stringify(wx), meetingId, userId, returnUrl, toCert },
      //         replace: true
      //       })
      //     } else {
      //       if (!isEmpty(meetingId)) {
      //         isNewUser(meetingId).then(res => {
      //           if (res.status == 200) {
      //             if (res.data) {
      //               InviteUser(meetingId, userId)
      //             }
      //           }
      //         })
      //       }

      //       setAuthorization({
      //         token: result,
      //         expireAt: new Date(new Date().getTime() + 240 * 60 * 1000)
      //       })
      //       getUserInfo().then(user => {
      //         /**
      //          * Enable  false 启用   true  禁用
      //          * isDelete  false 未删除  true  删除
      //          */
      //         // if (!user.Enabled) {
      //         //   Toast.fail('账号已禁用！')
      //         //   logout()
      //         //   return
      //         // }
      //         // if (user.IsDelete) {
      //         //   Toast.fail('账号已删除！')
      //         //   logout()
      //         //   return
      //         // }
      //         store.commit('account/setUser', user)
      //         if (checkUserSetting(user)) {
      //           checkSubscribe().then(res => {
      //             if (res.subscribe == 0) {
      //               window.location.href =
      //                 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMzI1ODc3MA==#wechat_redirect'
      //             } else {
      //               if (!isEmpty(meetingId)) {
      //                 next({
      //                   path: '/meetDetails',
      //                   query: {
      //                     meetId: this.meetingId
      //                   },
      //                   replace: true
      //                 })
      //               } else if (!isEmpty(returnUrl)) {
      //                 next({
      //                   path: returnUrl,
      //                   replace: true
      //                 })
      //               } else if (toCert && user.Identity !== CERT_TYPE.DOCTOR) {
      //                 next({
      //                   path: '/cert',
      //                   replace: true
      //                 })
      //               } else {
      //                 next({
      //                   path: '/home',
      //                   replace: true
      //                 })
      //               }
      //             }
      //           })
      //         } else {
      //           next({
      //             path: '/improve',
      //             query: to.query,
      //             replace: true
      //           })
      //         }
      //       })
      //     }
      //   })
      // })
    } else {
      next({
        query: {
          currentTab: currentTab
        }
      });
    }
  } else {
    // 如果从login页面返回,并且有returnUrl,则返回returnUrl
    if (from.name === 'pLogin' && from.query.backUrl && to.name !== 'Home') {
      next({
        path: from.query.backUrl,
        replace: true
      });
    } else {
      next();
    }
  }
});
export default router;