import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * 基础路由
 * @type { *[] }
 */
export var constantRouterMap = [
// {
//   path: '/login',
//   name: 'login',
//   component: () => import('@/views/login/index'),
//   meta: { title: '登录', keepAlive: false, customer: false, home: false }
// },
{
  path: '/plogin',
  name: 'pLogin',
  component: function component() {
    return import('@/views/login/plogin');
  },
  meta: {
    title: '手机号登录',
    keepAlive: false,
    customer: false,
    home: false
  }
}, {
  path: '/improve',
  name: 'Improve',
  component: function component() {
    return import('@/views/login/improve');
  },
  meta: {
    title: '完善信息',
    keepAlive: false,
    customer: false,
    home: false
  }
}, {
  path: '/',
  component: function component() {
    return import('@/views/main/index');
  },
  redirect: '/home',
  meta: {
    title: '主页',
    keepAlive: false,
    customer: true,
    home: false
  },
  children: [{
    path: '/home',
    name: 'Home',
    component: function component() {
      return import('@/views/home/index');
    },
    meta: {
      title: '首页',
      keepAlive: false,
      customer: true,
      home: false
    }
  }, {
    path: '/meeting',
    name: 'Meeting',
    component: function component() {
      return import('@/views/meeting/index');
    },
    meta: {
      title: '会议',
      keepAlive: false,
      customer: true,
      home: false
    }
  }, {
    path: '/caurse',
    name: 'Caurse',
    component: function component() {
      return import('@/views/caurse/index');
    },
    meta: {
      title: '课程',
      keepAlive: false,
      customer: true,
      home: false
    }
  }, {
    path: '/center',
    name: 'Center',
    component: function component() {
      return import('@/views/center/index');
    },
    meta: {
      title: '个人中心',
      keepAlive: false,
      customer: true,
      home: false
    }
  }, {
    path: '/consult',
    name: 'Consult',
    component: function component() {
      return import('@/views/consult/index');
    },
    meta: {
      title: '更多站内信息',
      keepAlive: false,
      customer: true,
      home: false
    }
  }]
}, {
  path: '/cert',
  name: 'Cert',
  component: function component() {
    return import('@/views/cert/index');
  },
  meta: {
    title: '身份认证',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/certApply',
  name: 'CertApply',
  component: function component() {
    return import('@/views/cert/certApply');
  },
  meta: {
    title: '身份认证',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/favorite',
  name: 'Favorite',
  component: function component() {
    return import('@/views/favorite/index');
  },
  meta: {
    title: '我的收藏',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/certificate',
  name: 'Certificate',
  component: function component() {
    return import('@/views/certificate/index');
  },
  meta: {
    title: '我的证书',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/certificateDetails',
  name: 'CertificateDetails',
  component: function component() {
    return import('@/views/certificate/indexDetails');
  },
  meta: {
    title: '证书详情',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/consultDetails',
  name: 'ConsultDetails',
  component: function component() {
    return import('@/views/home/indexDetails');
  },
  meta: {
    title: '资讯详情',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/expertDetails',
  name: 'ExpertDetails',
  component: function component() {
    return import('@/views/caurse/expertDetails');
  },
  meta: {
    title: '名师讲堂',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/caurseDetails',
  name: 'CaurseDetails',
  component: function component() {
    return import('@/views/caurse/indexDetails');
  },
  meta: {
    title: '课程详情',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/wxBrowserTip',
  name: 'WxBrowserTip',
  component: function component() {
    return import('@/views/wxBrowserTips/index');
  },
  meta: {
    title: '课件下载',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/trains',
  name: 'Trains',
  component: function component() {
    return import('@/views/trains/index');
  },
  meta: {
    title: '培训管理',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/question',
  name: 'Question',
  component: function component() {
    return import('@/views/question/index');
  },
  meta: {
    title: '调查问卷',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/userinfo',
  name: 'Userinfo',
  component: function component() {
    return import('@/views/userinfo/index');
  },
  meta: {
    title: '个人信息',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/resetName',
  name: 'ResetName',
  component: function component() {
    return import('@/views/userinfo/resetName');
  },
  meta: {
    title: '修改昵称',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/meetDetails',
  name: 'MeetDetails',
  component: function component() {
    return import('@/views/meeting/indexDetails');
  },
  meta: {
    title: '会议详情',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/smeetDetails',
  name: 'SMeetDetails',
  component: function component() {
    return import('@/views/meeting/smeetDetails');
  },
  meta: {
    title: '系列会议详情',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/meetSign',
  name: 'MeetSign',
  component: function component() {
    return import('@/views/meeting/meetSign');
  },
  meta: {
    title: '会议签到',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/invitationMode',
  name: 'InvitationMode',
  component: function component() {
    return import('@/views/meeting/invitationMode');
  },
  meta: {
    title: '邀约榜模板',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/invitation',
  name: 'Invitation',
  component: function component() {
    return import('@/views/meeting/invitation');
  },
  meta: {
    title: '邀约榜',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/record',
  name: 'Record',
  component: function component() {
    return import('@/views/record');
  },
  meta: {
    title: '学习记录',
    keepAlive: false,
    customer: true,
    home: true
  }
}, {
  path: '/instruction',
  name: 'Instruction',
  component: function component() {
    return import('@/views/instruction/index');
  },
  meta: {
    title: '用户须知',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/privacy',
  name: 'Privacy',
  component: function component() {
    return import('@/views/privacy/index');
  },
  meta: {
    title: '隐私须知',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/consultation',
  name: 'Consultation',
  component: function component() {
    return import('@/views/consultation/index');
  },
  meta: {
    title: '专家会诊',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/createConsultation',
  name: 'CreateConsultation',
  component: function component() {
    return import('@/views/consultation/createConsultation');
  },
  meta: {
    title: '发起会诊',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/detailsConsultation',
  name: 'DetailsConsultation',
  component: function component() {
    return import('@/views/consultation/detailsConsultation');
  },
  meta: {
    title: '会诊诊单',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/summary',
  name: 'Summary',
  component: function component() {
    return import('@/views/consultation/summary');
  },
  meta: {
    title: '会诊报告',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/success',
  name: 'Success',
  component: function component() {
    return import('@/views/consultation/success');
  },
  meta: {
    title: '会诊提交成功',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/modifyConsultation',
  name: 'ModifyConsultation',
  component: function component() {
    return import('@/views/consultation/modifyConsultation');
  },
  meta: {
    title: '会诊详情',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/forum',
  name: 'forum',
  component: function component() {
    return import('@/views/forum/index');
  },
  meta: {
    title: '  ',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '/forumDetail',
  name: 'forumDetail',
  component: function component() {
    return import('@/views/forum/forumDetail');
  },
  meta: {
    title: '论坛详情',
    keepAlive: false,
    customer: false,
    home: true
  }
}, {
  path: '*',
  name: 'plogin',
  component: function component() {
    return import('@/views/login/plogin');
  },
  meta: {
    title: '手机号登录',
    keepAlive: false,
    customer: false,
    home: false
  }
}];