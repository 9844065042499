import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import Vue from 'vue';
import * as filter from './filter';
Object.keys(filter).forEach(function (k) {
  return Vue.filter(k, filter[k]);
});
Vue.prototype.$formatDate = Vue.filter('formatDate');
Vue.prototype.$hidePhone = Vue.filter('hidePhone');
Vue.prototype.$formatUtc = Vue.filter('formatUtc');
Vue.prototype.$formatUtcTime = Vue.filter('formatUtcTime');
Vue.prototype.$formatUtcMilliseconds = Vue.filter('formatUtcMilliseconds');
Vue.prototype.$diffUtc = Vue.filter('diffUtc');
Vue.prototype.$getHourMin = Vue.filter('getHourMin');
Vue.prototype.$weixinShare = Vue.filter('weixinShare');
Vue.prototype.$formatCount = Vue.filter('formatCount');
Vue.prototype.$projectName = process.env.PROJECT_NAME;